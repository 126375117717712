/*
 * Human Cell Atlas
 * https://www.humancellatlas.org/
 *
 * Set of survey names.
 */

export const SurveyName = {
  "2021_SPRING_MATRIX_UX": "2021 Spring Matrix UX",
};
